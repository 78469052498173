import { dropPoolStore } from "@/store/pinia_modules/dropPoolStore";
import { navigationStore } from "@/store/pinia_modules/navigationStore";
import turf from "@turf/circle";
export function getGeolocationPermissions(): Promise<string> {
	return new Promise((resolve) => {
		if (navigator.geolocation) {
			navigator.permissions
				.query({ name: "geolocation" })
				.then(function (result) {
					resolve(result.state);
				});
		} else {
			resolve("not supported");
		}
	});
}

export function getUserLocation() {
	const dropPoolState = dropPoolStore();

	const id = dropPoolState.getWatcher;

	if (id != -1) {
		navigator.geolocation.clearWatch(id);

		dropPoolState.saveWatcher(-1);
	}
	const watcher = navigator.geolocation.watchPosition(
		(position: {
			coords: {
				latitude: number;
				longitude: number;
				accuracy: number;
			};
		}) => {
			dropPoolState.saveUserLocation([
				position.coords.longitude,
				position.coords.latitude,
			]);
			dropPoolState.saveLocationAccuracy(position.coords.accuracy);
		},
		(error) => {
			const errors: any = {
				1: "You have denied location permissions in your phone settings. Please allow location permissions in order to continue.",
				2: "Your position is currently not available",
				3: "Location request timeout error",
			};
			console.log(errors[error.code]);

			dropPoolState.loading = false;
			if (error.code == 1) {
				dropPoolState.saveLocationAccuracy(-1);
				navigationStore().saveDeniedPermissions("Geolocation" as never);
				navigationStore().emitAction({
					type: "go-to-page",
					value: "permissions",
				});
			}
		},
		{ enableHighAccuracy: true, maximumAge: 2000, timeout: 5000 }
	);
	dropPoolState.saveWatcher(watcher);
}

export function isInRadius(
	userLocation: [number, number],
	location: [number, number],
	radius: number
): boolean {
	const distance = calcCrow(
		userLocation[1],
		userLocation[0],
		location[1],
		location[0]
	);
	if (distance <= radius) {
		return true;
	} else {
		return false;
	}
}

export function calcCrow(
	lat1: number,
	lon1: number,
	lat2: number,
	lon2: number
): number {
	if (lat1 == lat2 && lon1 == lon2) {
		return 0;
	} else {
		const radlat1 = (Math.PI * lat1) / 180;
		const radlat2 = (Math.PI * lat2) / 180;
		const theta = lon1 - lon2;
		const radtheta = (Math.PI * theta) / 180;
		let dist =
			Math.sin(radlat1) * Math.sin(radlat2) +
			Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = (dist * 180) / Math.PI;
		dist = dist * 60 * 1.1515;
		dist = dist * 1.609344;
		return dist * 1000;
	}
}

export function getRandomLocations(
	center: [number, number],
	amount: number,
	radiusInMeters: number
): [number, number][] {
	const coordinates: [number, number][] = [];
	function addLocations() {
		if (amount > 0) {
			const random = randomCoordinateInRadius(center, radiusInMeters);
			const distance = calcCrow(center[1], center[0], random[0], random[1]);
			console.log(distance);
			if (distance > 10) {
				coordinates.push(random);
				amount--;
				addLocations();
			} else {
				addLocations();
			}
		}
	}
	addLocations();
	return coordinates;
}

function randomCoordinateInRadius(
	center: [number, number],
	radius: number
): [number, number] {
	const randomCoordinates = getRandomCoordinates(radius, true);
	const earth = 6378137;

	const northOffset = randomCoordinates[0],
		eastOffset = randomCoordinates[1];

	const offsetLatitude = northOffset / earth,
		offsetLongitude =
			eastOffset / (earth * Math.cos(Math.PI * (center[0] / 180)));

	return [
		center[1] + offsetLongitude * (180 / Math.PI),
		center[0] + offsetLatitude * (180 / Math.PI),
	];
}

function getRandomCoordinates(
	radius: number,
	uniform: boolean
): [number, number] {
	let a = Math.random(),
		b = Math.random();
	if (uniform) {
		if (b < a) {
			const c = b;
			b = a;
			a = c;
		}
	}
	return [
		b * radius * Math.cos((2 * Math.PI * a) / b),
		b * radius * Math.sin((2 * Math.PI * a) / b),
	];
}

export const addDropZoneCircles = (
	circles: { latitude: number; longitude: number; radius: number }[]
) => {
	const features: any[] = [];
	for (let i = 0; i < circles.length; i++) {
		features.push(
			turf([circles[i].longitude, circles[i].latitude], circles[i].radius, {
				units: "kilometers",
			})
		);
	}
	return {
		type: "geojson",
		data: {
			type: "FeatureCollection",
			features: features,
		},
	};
};
